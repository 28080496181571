import React from "react";
import { NavLink } from "react-router-dom"
import { throttle } from "lodash";
import Logo from "./LogoComponent";
import ScrollToTop from "../ScrollToTop";

class Header extends React.Component{

    constructor(props){
        super(props);

        this.handleMenu = this.handleMenu.bind(this);
        this.handleMenuWhenWidthChange = this.handleMenuWhenWidthChange.bind(this)
        this.updateBrowserWidth = this.updateBrowserWidth.bind(this)
        this.resizeThrottle = throttle(this.updateBrowserWidth, 500).bind(this)

        this.state = {
            menuIconClosed: true,
            browserWidth: 0
        }
    }

    componentDidMount(){
        this.updateBrowserWidth();
        window.addEventListener("resize", this.resizeThrottle);
        // window.addEventListener("resize", this.updateBrowserWidth);
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this.updateBrowserWidth);
    }

    updateBrowserWidth(){
        this.setState({browserWidth: window.innerWidth})
        this.handleMenuWhenWidthChange();
    }

    handleMenuWhenWidthChange(){
        if(this.state.browserWidth >= 1010){
            this.setState({menuIconClosed: true})
        }
    }


    handleMenu = () =>{
		//Om menuIconClosed = false -> menuIconClosed = true och vice versa
        this.setState({
			menuIconClosed: !this.state.menuIconClosed
        })
    }

    render(){
        return(
            <header className="site-header">
                
                {/* Klock- och datumgrejen */}
                <Logo />
                
                {/* Menyikonen */}
                <div 
                    onClick = {this.handleMenu}
                    className = {this.state.menuIconClosed ? "site-header__menu-icon" : "site-header__menu-icon site-header__menu-icon--close-x"}
			    >
	    			<div className="site-header__menu-icon__middle"></div>
			    </div>

                {/* Menyn och menylänkarna */}
                <div className= {this.state.menuIconClosed ? "site-header__menu-content" : "site-header__menu-content site-header__menu-content--is-visible"}>

                    <nav className="primary-nav primary-nav--pull-right">
                        <ScrollToTop>
                            <ul>
                                <li>
                                    <NavLink 
                                    to="/" 
                                    onClick ={this.handleMenu} 
                                    className={(navData) => navData.isActive ? "is-active" : "" }
                                    >
                                    Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                    to="/about"  
                                    onClick ={this.handleMenu}
                                    className={(navData) => navData.isActive ? "is-active" : "" }
                                    >
                                    About Me
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                    to="/jobs" 
                                    onClick ={this.handleMenu}
                                    className={(navData) => navData.isActive ? "is-active" : "" }
                                    >
                                    Professional Experiences
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                    to="/courses" 
                                    onClick = {this.handleMenu}
                                    className={(navData) => navData.isActive ? "is-active" : "" }
                                    >
                                    Courses, Education and Skills
                                    </NavLink>

                                </li>
                                <li>
                                    <NavLink 
                                    to="/demos" 
                                    onClick ={this.handleMenu}
                                    className={(navData) => navData.isActive ? "is-active" : "" }
                                    >
                                    Demos
                                    </NavLink>
                                </li>
                            </ul>
                        </ScrollToTop>
                    </nav>
                </div>


            </header>
        )
    }
}

export default Header;