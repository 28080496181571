import React from "react"

class Clock extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            time: new Date().toLocaleTimeString()
        }
    }

    componentDidMount(){
        this.timer = setInterval(()=> this.tick(), 1000);
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    tick(){
        this.setState({
            time: new Date().toLocaleTimeString()
        })
    }

    render(){
        return(
            <p>{this.state.time}</p>
        )
    }
}

export default Clock;