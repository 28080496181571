import React from "react"

function VideoLink(props){
	return(
        <tr>
            <td colspan = "2">
                <div className="responsive-container">
                    <iframe title="video demo" className="responsive-iframe" src={props.url} allowFullScreen></iframe>
                </div>
            </td>
        </tr>
	)
}

export default VideoLink;