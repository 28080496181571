import React from "react"
//import {useState} from "react";
import Klocka from "./Clock"
import Datum from "./ShowDate"

class Logo extends React.Component{

    constructor(props){
        super(props)

        this.state ={
            count: 0
        }
    }

    componentDidMount(){
        this.timer = setInterval(()=> this.tick(), 4000);
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    tick(){
        this.setState(prevState => ({
            count: prevState.count+1
        }))
    }

    render(){
        return(
            <div className= "site-header__logo">

                {this.state.count % 3 ===0 && <p>Fredriks lilla hemsida</p>}
                {this.state.count % 3 ===2 && <Klocka />}
                {this.state.count % 3 ===1 && <Datum/>}

            </div>
        )
    }
}

export default Logo;