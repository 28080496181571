import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles.scss';
import Router from './components/Router/Router';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router />
  //  <React.StrictMode>
  // </React.StrictMode>
);

