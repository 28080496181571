import  React from "react";
import { fetchData } from "../components/SharedScripts";
import Course from "../components/Course";
// import database from "../firebase/database";
import Spinner from "../components/Spinner"


class Courses extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            courses: [],
            loading: true
        }
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = async()=>{
        
        // !sessionStorage.getItem("courses") kommer att släppa igenom en tom array.
        // Försök att hitta på något bättre! 
        if(!sessionStorage.getItem("courses")){

            await fetchData("courses")
            .then(res=>{
                return this.sortData(res)
            })
            .then(res=>{
                this.storeData(res)
                sessionStorage.setItem("courses", JSON.stringify(res))
            })


        } else {
            this.storeData(JSON.parse(sessionStorage.getItem("courses")))
        }
    }

    storeData = async (data) => {
        this.setState(()=>({
            courses: data,
            loading: false
        }))
    }


    sortData = (arrayToBeSorted) =>{

        return arrayToBeSorted.sort((a, b) =>{
            let aDate = a.dateOfCompletion;
            let bDate = b.dateOfCompletion;
        
            if(aDate < bDate){
                return 1;
            }
            if(aDate > bDate){
                return -1;
            }
    
            return 0;
        })
    }


    render(){
        return(
            <div className="wrapper">
                <div className = "wrapper wrapper__content">
                    
                    < Spinner
                        loading = {this.state.loading}
                    />
                    
                    
                    <table>
                        
                        {!this.state.loading && ([
                            <caption>Visit <a href="https://www.udemy.com/" target="_blank" rel="noreferrer">Udemy</a> on the web. Visit <a href="https://www.hermods.se/" target="_blank" rel="noreferrer">Hermods</a> on the web</caption>,
                            
                            <thead>
                                <tr>
                                    <th colSpan = "2">Courses</th>
                                </tr>
                            </thead>
                        ])}

                        <tbody>
                            {this.state.courses.map((course, index) => {
                                return(
                                    <Course
                                        key = {course.id}
                                        id = {course.id}
                                        course = {course.course}
                                        instructor = {course.instructor}
                                        platform = {course.platform}
                                        dateOfCompletion = {course.dateOfCompletion}
                                    />
                                )
                               
                            })}
                        </tbody>
                    </table>
                    {!this.state.loading && ([
                        <table>
                        <thead>
                            <tr>
                                <th colSpan = "2">Languages</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key="lan0"><td colSpan = "2"><strong>Swedish</strong> - native</td></tr>
                            <tr key="lan1"><td colSpan = "2"><strong>English</strong> - above average, spoken and written</td></tr>
                        </tbody>
                        </table>,

                        <table>
                            <thead>
                                <tr>
                                    <th colSpan = "2">Miscellaneous IT related skills</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key="it0"><td colSpan = "2">Windows OS</td></tr>
                                <tr key="it1" ><td colSpan = "2">Linux OS (on an elementary level)</td></tr>
                                <tr key="it2"><td colSpan = "2">Visual Studio</td></tr>
                                <tr key="it3"><td colSpan = "2">Visual Studio Code</td></tr>
                                <tr key="it4"><td colSpan = "2">Selected parts of the MS Office suite</td></tr>
                                <tr key="it5"><td colSpan = "2">Selected parts of the Adobe CS suite</td></tr>
                                <tr key="it6"><td colSpan = "2">Adobe Lightroom</td></tr>
                            </tbody>
                        </table>,
                        
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan = "2">Education</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key="ed0"><td colSpan = "2">Between 2001-2004 I attended the Mid Sweden University for courses in science, mathematics and technology.</td></tr>
                            </tbody>
                        </table>            
                    ])}



                </div>
            </div> 
        )
    }
}

export default Courses;