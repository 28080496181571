import React from "react";

function Job(props){
    return(
        <>
            <tr 
            // key={props.id.concat(0)}
            >
                <td className="postTitle">{props.title}</td>
                <td rowSpan="3">{props.description}</td>
            </tr>
            <tr
             //key={props.id.concat(1)}
            >
                <td>{props.employer}</td>
            </tr>
            <tr className="postBottom"
            //key={props.id.concat(2)}
            >
                <td>{props.period}</td>
            </tr>
        </>
    )
}

export default Job;