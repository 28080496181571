import React from "react";
import LillFredrikBild from "../components/LillFredrikBild";
import Citat from "../components/Citat"

const Home = () =>{
    return(
        <div className="wrapper">
            <div className = "wrapper wrapper__content">
                <div className="wrapper__content-text">
                    <LillFredrikBild />
                    <Citat page="Home" />

                    <div>
                        <p>
                            My name is Fredrik and this is my small corner of the world wide web. Who knows, maybe you'll find something interesting here! And if you do, don't hesitate to get in touch!<br/><br/>
                            The key words for this project, which is an ongoing work in progress, are <em>presentation</em>, <em>experimentation</em>, <em>frustration</em> and <em>self education</em>.
                        </p>
                        
                        <ul>
                            <li>
                                <b>Presentation</b> - one of the initial thoughts behind this website was to let it be a presentation - a bit like my CV on the web - which I could refer to when needed. 
                            </li>
                            <li>
                                <b>Experimentation</b> - This homepage provides me, at the same time, with a playground for experiments with HTML, CSS and JS.<br/><br/>
                                Yes, it would have been an easy solution to use an existing social media platform to present myself and eventual demos and projects. But then I wouldn't have had the same motivation to tinker with mentioned technologies. 
                            </li>
                            <li>
                                <b>Frustration</b> and <b>self education</b> follows quite naturally the experimentation clause! 😅
                            </li>
                    
                        </ul>
                        <div className="note">
                        
                        I've remade my "little homepage". But since I was kind of fond of the rather conventional, perhaps rigid, design of my old homepage, it looks almost the same as it did before. 
                        I've mainly worked with ReactJS, React Router, SASS and Firebase. 
                        <br/><br/>/Fredrik, 2022-09-04
                        

                        </div>
                    </div>

                </div>

            </div>
        </div>


        
    )
}

export default Home;