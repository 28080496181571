import { React } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import Header from "../Header/Header";
import About from "../../Pages/About";
import Courses from "../../Pages/Courses";
import Demos from "../../Pages/Demos";
import Home from "../../Pages/Home";
import Jobs from "../../Pages/Jobs";

function Router(){
    return(
        <HashRouter basename="">
            <Header />
            <Routes>
                <Route 
                    path="/"
                    element = {<Home /> }
                />
                <Route 
                    path="/about"
                    element = {<About /> }
                />
                <Route 
                    path="/courses"
                    element = {<Courses /> }
                />
                <Route 
                    path="/demos"
                    element = {<Demos /> }
                />
                <Route 
                    path="/jobs"
                    element = {<Jobs /> }
                />
                <Route 
                    path="*"
                    element = {<Home /> }
                />
            </Routes>
        </HashRouter>
    )
}

export default Router;