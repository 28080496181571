import React from "react"

function DemoLink(props){
	return(
        <tr>
            <td colspan = "2">
                <div>
                    <p><a href={props.url} target="_blank"  rel="noreferrer">{props.title}</a></p>
                    <p>{props.description}</p>
                </div>
            </td>
        </tr>
	)
}

export default DemoLink;