// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore } from "firebase/firestore";
import firebase from 'firebase/compat/app';

//Kanske till senare
// import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAW_km2OybAPw1tPOoMpfbTH3Tdd4hc-DU",
  authDomain: "hemsidajobbochkurser.firebaseapp.com",
  databaseURL: "https://hemsidajobbochkurser.firebaseio.com",
  projectId: "hemsidajobbochkurser",
  storageBucket: "hemsidajobbochkurser.appspot.com",
  messagingSenderId: "215589624869",
  appId: "1:215589624869:web:1157738194c84a9ea7f3cc",
  measurementId: "G-KHFC6Z1661"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const app = firebase.initializeApp(firebaseConfig);
const fireDatabase = getFirestore(app);
// Kanske till senare
// const fireAuth = getAuth(app);

export { fireDatabase }