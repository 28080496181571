import React from "react"

const About =()=>{
    return(
        <div className="wrapper">
            <div className = "wrapper wrapper__content">
                <div className="wrapper__content-text">

                    <span className="headline">Brief introduction</span>
                    <p>
                        I enjoy working with problem solving, where I try to find different perspectives, points of attack and creative solutions.
                    </p>
                    <p>
                        I appreciate tasks where I feel that I have the opportunity to learn, grow and evolve on an intellectual level.
                    </p>
                    <p>
                        I am well comfortable working with computers. I bought my first Windows PC 1994. Since then I have used computers professionally and for leisure activities as well as for experiments with programming. Mainly Javascript and C# but I have also tinkered a bit with Python and SQL.
                    </p>
                    <p>
                        I am considered a good teacher with a large amount of patience and the ability to teach and explain in ways both effective and understandable.
                    </p>
                    <p>
                        I enjoy seeing ideas and solutions implemented. That way you can get a quick evaluation of the work, learn and, if necessary, take it to the next step!
                    </p>
                    <span className="headline">
                        Personal
                    </span>
                    <p>
                        I have a strong creative urge that usually manifests itself through my interests in photography, music and literature. I shoot as often as I can. Even though I find great pleasure in arranging more artistic photo sessions.
                    </p>
                    <p>
                        Music is a strong interest and a very essential part of my nature as it is such a multidimensional form of expression. However, I don't listen to music as much as I practice with, among other things, the guitar.
                    </p>
                    <p>
                        My literary interest is expressed not only through that I like to read, but also through writing. I write both prose and poetry and find great pleasure in playing with words!
                    </p>
                    <p>
                        I enjoy travelling. I have visited many countries in Europe, but England is the country I like the best. I usually spend a couple of weeks of my holidays in the English countryside. I usually always end up down in the New Forest where I walk out onto the moors with the camera to take pictures of the horses that roam freely in the area.
                    </p>
                    <span className="headline">
                        Extracurricular activities
                    </span>
                    <ul>
                        <li>Casually playing <a href="https://worldofwarcraft.com/" target="_blank" rel="noreferrer">World of Warcraft</a></li>
                        {/* <li>Regular meetings with immigrants and refugees to facilitate integration and language skills</li> */}
                        <li>Member of <a href="https://www.mensa.se/" target="_blank" rel="noreferrer">Mensa Sweden</a></li>
                    </ul>
        
                    <span className="headline">
                        Contact Information
                    </span>
                    <p>
                        If someone would like to get in touch, you can find me on <a href="https://www.linkedin.com/in/fredrik-wallin-27355a197/" target="_blank" rel="noreferrer">LinkedIn</a>
                    </p>

                </div>

            </div>
        </div>

    )
}

export default About;