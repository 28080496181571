import React from "react";

class Citat extends React.Component{

    constructor(props){
        super(props)

        // this.hanteraCitat = this.hanteraCitat.bind(this);

        this.state={
            saidAboutMe: {
                hoj: {
                    quote: `"You ought to be locked up in a glass box, tagged 'In case of emergency - break the glass!'"`,
                    source: "Former employer", 
                    comment: "(To this day, I honestly don't know if this was said as a compliment or uttered in sheer frustration...!)"
                },
                irene: {
                    quote: `"Fredrik has performed his duties with great accuracy and great interest to our full satisfaction. He has shown great sense of responsibility and is also easy to work with..."`,
                    source: "Irene O., Production Manager, Solectron/Flextronics", 
                    comment: ""
                }
            }
        }

    }
    
    hanteraCitat =() =>{
        let quote = "", source = "", comment = "";
        const hoj = this.state.saidAboutMe.hoj;
        const irene = this.state.saidAboutMe.irene;

        if(this.props.page === "Home"){
            quote = hoj.quote;
            source = hoj.source;
            comment = hoj.comment;
            
        } else if(this.props.page === "Jobs"){
            quote = irene.quote;
            source = irene.source;
            comment = irene.comment;
        }

        return <div className="citat">
            <p>{quote}</p>
            <p>- {source}</p>
            {comment && (
                <div className ="citat__kommentar">
                    <p>{comment}</p>
                </div>
            )}        
        </div>
    }

    render() {
        return(
            <div>
                { this.hanteraCitat() }
			</div>
        )
    }
}

export default Citat;