import React from "react"

function Course(props){
    
    // const id = props.key;

    return(
        <>
            <tr 
            // key = {id.concat(0)}
            >
            <td className="postTitle" rowSpan="3">{props.course}</td>
            <td >Instructor: {props.instructor}</td>
            </tr>
            <tr 
            // key = {id.concat(1)}
            >
                <td>Platform: {props.platform}</td>
            </tr>
            <tr className="postBottom"
            // key = {id.concat(2)}
            >
                <td>Completed: {props.dateOfCompletion}</td>
            </tr>
        </>
    )
}

export default Course;