import React from "react";
import { fetchData } from "../components/SharedScripts";
import Job from "../components/Job";
import Citat from "../components/Citat"
import Spinner from "../components/Spinner"

class Jobs extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            jobs: [],
            loading: true
        }
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = async()=>{
        
        if(!sessionStorage.getItem("jobs")){

            await fetchData("jobs")
            .then(res=>{
                return this.sortData(res)
            })
            .then(res=>{
                this.storeData(res)
                sessionStorage.setItem("jobs", JSON.stringify(res))
            })
        } else {
            this.storeData(JSON.parse(sessionStorage.getItem("jobs")))
        }
        
    }

    sortData = (arrayToBeSorted) =>{

        return arrayToBeSorted.sort((a, b) =>{
            let aDate = a.period;
            let bDate = b.period;
        
            if(aDate < bDate){
                return 1;
            }
            if(aDate > bDate){
                return -1;
            }
    
            return 0;
        })
    }

    storeData = async (data) => {
        this.setState(()=>({
            jobs: data,
            loading: false
        }))
    }

    render(){
        return(
            <div className="wrapper">
                <div className = "wrapper wrapper__content">
                
                    <Spinner 
                        loading = {this.state.loading}
                    />

                    <div className ="wrapper__content-text">
                        {!this.state.loading && <Citat page ="Jobs" />}
                    </div>

                    <table>
                        {!this.state.loading && (

                            <thead>
                            <tr><th colSpan = "2">Professional Experiences</th></tr>
                            </thead>
                        )}

                        <tbody>
                            {this.state.jobs.map((job, index) => {

                                return (
                                    <Job 
                                    key = {job.id}
                                    id = {job.id}
                                    title = {job.title}
                                    description={job.description}
                                    employer = {job.employer}
                                    period = {job.period}
                                />)
                            })}                        
                        </tbody> 
                    </table>
                
                </div>
            </div> 
        )
    }
}

export default Jobs;