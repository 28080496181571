import React from "react"

const ShowDate = () => {
    const dateOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}

    return(
        <p>{new Date().toLocaleDateString("en",dateOptions)}</p>
    )

}

export default ShowDate;