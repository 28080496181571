import { fireDatabase } from "./init";
import {
    collection, 
    // deleteField,
    doc, 
    getDocs, 
    getDoc, 
    // setDoc, 
    // updateDoc 
} from "firebase/firestore"

class Database{

    verifyObject = async (collection, document) => {
        
        const ref = doc(fireDatabase, collection, document)
        const docSnap = await getDoc(ref)
        return docSnap.exists()
    }

    fetchData =async (samling)=>{

        console.log("fr fetchData ")

        let tempArr = []

        const querySnapshot = await getDocs(collection(fireDatabase, samling));
        querySnapshot.forEach((doc) => {
            let data = {id: doc.id, ...doc.data()}
            tempArr.push(data)
        });

        return tempArr
    }
}

export default new Database()