import database from "../firebase/database"


const fetchData = async (collection) =>{
    return await database.fetchData(collection)
    .then(res =>{
        return res
    })
}



export {fetchData}