import React from "react"
import { PuffLoader } from "react-spinners";
// import { css } from '@emotion/css'

// const override = css`
//     display: block;
//     margin: 0 auto 0 auto;
//     border-color: red;
// `;

const Spinner =(props)=>{

    return(
        <div className="spinner">
            <PuffLoader 
                // css = { override }
                size={50}
                color={"red"}
                loading={props.loading}
            />
        </div>
    )

}

export default Spinner;