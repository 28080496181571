import React from "react";
import Spinner from "../components/Spinner"
import { fetchData } from "../components/SharedScripts";
import VideoLink from "../components/VideoLink";
import DemoLink from "../components/DemoLink";

class Demos extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            demos: [],
            videos: [],
            loading: true
        }
    }

    componentDidMount(){
        this.fetchData("demos")
        .then(()=>{
            this.fetchData("videos")
        })
        .then(()=>{
            this.setState(()=>({
                loading: false
            }))            
        })
    }

    fetchData = async(collection)=>{
        
        // !sessionStorage.getItem("courses") kommer att släppa igenom en tom array.
        // Försök att hitta på något bättre! 
        if(!sessionStorage.getItem(collection)){

            await fetchData(collection)
            .then(res=>{
                return this.sortData(res)
            })
            .then(res=>{
                this.storeData(res, collection)
                sessionStorage.setItem(collection, JSON.stringify(res))
            })


        } else {
            this.storeData(JSON.parse(sessionStorage.getItem(collection)), collection)
        }
    }

    storeData = async (data, field) => {
        
        this.setState(()=>({
            [`${field}`]: data,
            // loading: false
        }))
    }


    sortData = (arrayToBeSorted) =>{

        return arrayToBeSorted.sort((a, b) =>{
            let aDate = a.added;
            let bDate = b.added;
        
            if(aDate < bDate){
                return 1;
            }
            if(aDate > bDate){
                return -1;
            }
    
            return 0;
        })
    }

    render(){
        return(
            <div className="wrapper">
                <div className = "wrapper wrapper__content">
                    
                    <Spinner 
                        loading= {this.state.loading}
                    />
                    
                    <table>
                        <thead>
                            <tr>
                                <th colspan = "2">Demos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.demos.map((demo, index)=>{
                                return (
                                    <DemoLink
                                    key = { demo.id }
                                    url = { demo.url }
                                    title = { demo.title } 
                                    description = { demo.description }
                                />
                                )    
                            })}
                        </tbody>
                    </table>
                    <table>

                        <thead>
                            <tr>
                                <th colspan = "2">Videos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.videos.map((video, index)=>{
                                return (
                                    <VideoLink
                                    key = { video.id }
                                    url = { video.url }
                                />
                                )    
                            })}
                        </tbody>
				    </table>
                </div>
            </div> 
        )
    }
}


export default Demos;